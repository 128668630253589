<script setup lang="ts">
const emit = defineEmits<{
  close: []
  createLease: []
  createVariation: []
}>()
</script>

<template>
  <ModalBase
    title="Option Exercise"
    modal-class="max-w-[650px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800"
    primary-button-text="Cancel"
    :secondary-button-text="null"
    :close-on-click="false"
    @confirm="emit('close')"
    @close="emit('close')"
  >
    <div v-auto-animate class="grid gap-8">
      <div class="grid gap-6">
        <div class="grid gap-2">
          <span class="font-bold">
            Exercise this option through a Variation
          </span>
          <span class="text-sm text-gray-400"> (keep the same lease ID) </span>

          <hr class="bg-gray-750 my-2 h-px border-t-0" />

          <div>
            <Button
              icon="plus"
              color="opacityPrimary"
              class="inline-flex"
              @click="emit('createVariation')"
            >
              Variation
            </Button>
          </div>
        </div>

        <div class="grid gap-2">
          <span class="font-bold">
            Exercise this option through a New Lease
          </span>
          <span class="text-sm text-gray-400">
            (a new lease ID will be created)
          </span>

          <hr class="bg-gray-750 my-2 h-px border-t-0" />

          <div>
            <Button
              icon="plus"
              color="opacityPrimary"
              @click="emit('createLease')"
            >
              New Lease
            </Button>
          </div>
        </div>
      </div>
    </div>
  </ModalBase>
</template>
